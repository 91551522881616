var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.saveCustomer.apply(null, arguments)}}},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.typeOfAction))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns is-6 view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Kundennummer"}},[_vm._v(" #"+_vm._s(_vm.$utilities.padLeftWithNull(_vm.customer.id))+" ")])],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Firma","type":{ 'is-danger': _vm.$v.customer.company.$error },"message":{
              'Bitte geben Sie eine Firma an.':
                _vm.submitted && _vm.$v.customer.company.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.company.$error },model:{value:(_vm.$v.customer.company.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.company, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.company.$model"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Vorname","type":{ 'is-danger': _vm.$v.customer.firstname.$error },"message":{
              'Bitte geben Sie einen Vornamen ein.':
                _vm.submitted && _vm.$v.customer.firstname.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.firstname.$error },model:{value:(_vm.$v.customer.firstname.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.firstname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.firstname.$model"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nachname","type":{ 'is-danger': _vm.$v.customer.lastname.$error },"message":{
              'Bitte geben Sie einen Nachnamen ein.':
                _vm.submitted && _vm.$v.customer.lastname.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.lastname.$error },model:{value:(_vm.$v.customer.lastname.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.lastname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.lastname.$model"}})],1)],1)]),_c('div',{staticClass:"columns view-columns is-6"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Anschrift","type":{ 'is-danger': _vm.$v.customer.adress1.$error },"message":{
              'Bitte geben Sie eine Adresse ein.':
                _vm.submitted && _vm.$v.customer.adress1.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.adress1.$error },attrs:{"placeholder":"Adresszeile 1"},model:{value:(_vm.$v.customer.adress1.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.adress1, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.adress1.$model"}})],1),_c('b-field',{attrs:{"label":"Anschrift 2.Zeile (optional)","type":{ 'is-danger': _vm.$v.customer.adress2.$error },"message":{
              'Bitte geben Sie eine Adresse ein.':
                _vm.submitted && _vm.$v.customer.adress2.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.adress2.$error },attrs:{"placeholder":"Adresszeile 2"},model:{value:(_vm.$v.customer.adress2.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.adress2, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.adress2.$model"}})],1),_c('b-field',{attrs:{"label":"Postleitzahl","type":{ 'is-danger': _vm.$v.customer.zip.$error },"message":{
              'Bitte geben Sie eine PLZ ein.':
                _vm.submitted && _vm.$v.customer.zip.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.zip.$error },attrs:{"placeholder":"PLZ"},model:{value:(_vm.$v.customer.zip.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.zip, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.zip.$model"}})],1),_c('b-field',{attrs:{"label":"Ort","type":{ 'is-danger': _vm.$v.customer.city.$error },"message":{
              'Bitte geben Sie einen Ort ein.':
                _vm.submitted && _vm.$v.customer.city.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.city.$error },attrs:{"placeholder":"Ort"},model:{value:(_vm.$v.customer.city.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.city, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.city.$model"}})],1),_c('b-field',{attrs:{"label":"Land","type":{ 'is-danger': _vm.$v.customer.country_id.$error },"message":{
              'Bitte wählen Sie ein Land.':
                _vm.submitted && _vm.$v.customer.country_id.$error
            }}},[_c('b-select',{class:{ is_danger: _vm.$v.customer.country_id.$error },staticStyle:{"width":"inherit"},attrs:{"placeholder":"Bitte wählen Sie ein Land"},model:{value:(_vm.$v.customer.country_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.country_id, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.country_id.$model"}},_vm._l((_vm.countryList),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name_de)+" ")])}),0)],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Telefon","type":{ 'is-danger': _vm.$v.customer.telephone.$error },"message":{
              'Bitte geben Sie eine Telefonnummer ein.':
                _vm.submitted && _vm.$v.customer.telephone.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.telephone.$error },attrs:{"placeholder":"Telefon","icon":"phonelocal_phone"},model:{value:(_vm.$v.customer.telephone.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.telephone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.telephone.$model"}})],1),_c('b-field',{attrs:{"label":"Mobilnummer","type":{ 'is-danger': _vm.$v.customer.mobile.$error },"message":{
              'Bitte geben Sie eine Telefonnummer ein.':
                _vm.submitted && _vm.$v.customer.mobile.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.mobile.$error },attrs:{"placeholder":"Mobilnummer","icon":"mobile"},model:{value:(_vm.$v.customer.mobile.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.mobile, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.mobile.$model"}})],1),_c('b-field',{attrs:{"label":"E-Mail","type":{ 'is-danger': _vm.$v.customer.email.$error },"message":{
              'Bitte geben Sie eine gültige E-Mailadresse ein.':
                _vm.submitted && _vm.$v.customer.email.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.email.$error },attrs:{"placeholder":"E-Mail","icon":"email2"},model:{value:(_vm.$v.customer.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.email.$model"}})],1),_c('b-field',{attrs:{"label":"Newsletter","type":{ 'is-danger': _vm.$v.customer.newsletter.$error },"message":{
              'Bitte geben Sie eine gültige E-Mailadresse ein.':
                _vm.submitted && _vm.$v.customer.newsletter.$error
            }}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.customer.newsletter.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.newsletter, "$model", $$v)},expression:"$v.customer.newsletter.$model"}}),_vm._v(" Newsletter-Empfänger ")],1)],1)]),_c('div',{staticClass:"columns view-columns is-6"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Aktiv","type":{ 'is-danger': _vm.$v.customer.active.$error },"message":{
              '': _vm.submitted && _vm.$v.customer.active.$error
            }}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.customer.active.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.active, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.active.$model"}}),_vm._v(" Darf sich anmelden ")],1),_c('b-field',{attrs:{"label":"Sprache","type":{ 'is-danger': _vm.$v.customer.language.$error },"message":{
              'Bitte wählen Sie die Sprache.':
                _vm.submitted && _vm.$v.customer.language.$error
            }}},[_c('div',[_c('b-radio',{attrs:{"name":"language","native-value":"de","default":""},model:{value:(_vm.$v.customer.language.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.language, "$model", $$v)},expression:"$v.customer.language.$model"}},[_vm._v(" deutsch ")]),_c('b-radio',{attrs:{"name":"language","native-value":"en"},model:{value:(_vm.$v.customer.language.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.language, "$model", $$v)},expression:"$v.customer.language.$model"}},[_vm._v(" english ")])],1)])],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Bankverbindung","type":{ 'is-danger': _vm.$v.customer.direct_debt.$error },"message":{
              'Bitte geben Sie einen Vornamen ein.':
                _vm.submitted && _vm.$v.customer.direct_debt.$error
            }}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.customer.direct_debt.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.direct_debt, "$model", $$v)},expression:"$v.customer.direct_debt.$model"}}),_vm._v("Bankeinzug ")],1),_c('b-field',{attrs:{"label":"Name der Bank","type":{ 'is-danger': _vm.$v.customer.bank_name.$error },"message":{
              'Bitte geben Sie den Namen der Bank ein.':
                _vm.submitted && _vm.$v.customer.bank_name.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.bank_name.$error },attrs:{"placeholder":"Name der Bank"},model:{value:(_vm.$v.customer.bank_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.bank_name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.bank_name.$model"}})],1),_c('b-field',{attrs:{"label":"IBAN/Kontonummer","type":{ 'is-danger': _vm.$v.customer.bank_account.$error },"message":{
              'Bitte geben Sie Kontonummer/IBAN ein.':
                _vm.submitted && _vm.$v.customer.bank_account.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.bank_account.$error },attrs:{"placeholder":"IBAN/Kontonummer"},model:{value:(_vm.$v.customer.bank_account.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.bank_account, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.bank_account.$model"}})],1),_c('b-field',{attrs:{"label":"Bitte geben Sie BLZ/BIC ein.","type":{ 'is-danger': _vm.$v.customer.bank_bic.$error },"message":{
              'Bitte geben Sie die BLZ/BIC ein.':
                _vm.submitted && _vm.$v.customer.bank_bic.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.bank_bic.$error },attrs:{"placeholder":"BLZ/BIC"},model:{value:(_vm.$v.customer.bank_bic.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.bank_bic, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.bank_bic.$model"}})],1),_c('b-field',{attrs:{"label":"VAT-ID","type":{ 'is-danger': _vm.$v.customer.vat_id.$error },"message":{
              'Bitte geben Sie eine gültige VAT-ID ein.':
                _vm.submitted && _vm.$v.customer.vat_id.$error
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.vat_id.$error },attrs:{"placeholder":"VAT-ID"},model:{value:(_vm.$v.customer.vat_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.vat_id, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.vat_id.$model"}})],1)],1)]),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Separate Lieferadresse","type":{ 'is-danger': _vm.$v.customer.shipping_address.$error },"message":{
              'Bitte geben Sie eine gültige E-Mailadresse ein.':
                _vm.submitted && _vm.$v.customer.shipping_address.$error
            }}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.customer.shipping_address.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.shipping_address, "$model", $$v)},expression:"$v.customer.shipping_address.$model"}}),_vm._v(" Separate Lieferadresse ")],1),(_vm.customer.shipping_address == 1)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":"Name/Firmenname","type":{ 'is-danger': _vm.$v.customer.shipping_name.$error },"message":{
                  'Bitte geben Sie einen Nachnamen ein.':
                    _vm.submitted && _vm.$v.customer.shipping_name.$error
                }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.shipping_name.$error },model:{value:(_vm.$v.customer.shipping_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.shipping_name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.shipping_name.$model"}})],1),_c('b-field',{attrs:{"label":"Anschrift","type":{ 'is-danger': _vm.$v.customer.shipping_address1.$error },"message":{
                  'Bitte geben Sie einen Nachnamen ein.':
                    _vm.submitted && _vm.$v.customer.shipping_address1.$error
                }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.shipping_address1.$error },model:{value:(_vm.$v.customer.shipping_address1.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.shipping_address1, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.shipping_address1.$model"}})],1),_c('b-field',{attrs:{"label":"Anschrift 2. Zeile (optional)","type":{ 'is-danger': _vm.$v.customer.shipping_address2.$error },"message":{
                  'Bitte geben Sie einen Nachnamen ein.':
                    _vm.submitted && _vm.$v.customer.shipping_address2.$error
                }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.shipping_address2.$error },model:{value:(_vm.$v.customer.shipping_address2.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.shipping_address2, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.shipping_address2.$model"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":"Postleitzahl","type":{ 'is-danger': _vm.$v.customer.shipping_zip.$error },"message":{
                  'Bitte geben Sie einen Nachnamen ein.':
                    _vm.submitted && _vm.$v.customer.shipping_zip.$error
                }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.shipping_zip.$error },model:{value:(_vm.$v.customer.shipping_zip.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.shipping_zip, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.shipping_zip.$model"}})],1),_c('b-field',{attrs:{"label":"Ort","type":{ 'is-danger': _vm.$v.customer.shipping_city.$error },"message":{
                  'Bitte geben Sie einen Nachnamen ein.':
                    _vm.submitted && _vm.$v.customer.shipping_city.$error
                }}},[_c('b-input',{class:{ is_danger: _vm.$v.customer.shipping_city.$error },model:{value:(_vm.$v.customer.shipping_city.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.shipping_city, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.shipping_city.$model"}})],1),_c('b-field',{attrs:{"label":"Land","type":{
                  'is-danger': _vm.$v.customer.shipping_country_id.$error
                },"message":{
                  'Bitte wählen Sie ein Land.':
                    _vm.submitted && _vm.$v.customer.shipping_country_id.$error
                }}},[_c('b-select',{class:{
                    is_danger: _vm.$v.customer.shipping_country_id.$error
                  },staticStyle:{"width":"inherit"},attrs:{"placeholder":"Bitte wählen Sie ein Land"},model:{value:(_vm.$v.customer.shipping_country_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.customer.shipping_country_id, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.customer.shipping_country_id.$model"}},_vm._l((_vm.countryList),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name_de)+" ")])}),0)],1)],1)]):_vm._e()],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Schließen")]),_vm._v("   "),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","native-type":"submit","icon-left":"cloud_upload"}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }